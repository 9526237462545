.default-bg-color {
  background: #9e9e9e;
}

.dark-bg-color {
  background: #616161;
}

.light-bg-color {
  background: #eee;
}

.default-text-color {
  color: #272727 !important;
}

.primary-text-color {
  color: #757575 !important;
}

.secondary-text-color {
  color: #41768f !important;
}

.divider-color {
  border-color: #bdbdbd;
}

.element-color {
  background: #41768f;
}

.icon-color {
  color: #607d8b;
}

.icon-color:hover {
  color: #1f282c;
}

.main-bg {
  background: linear-gradient(135deg, #41768e12 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, #eceddc 25%, #fff0 25%) -50px 0,
    linear-gradient(315deg, #eceddc 25%, #fffdfd00 25%),
    linear-gradient(45deg, #41768e12 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: #e2e2e2;
}

.hide {
  display: none !important;
}

.margin-left {
  margin-left: 15px !important;
}

.padding-left {
  padding-left: 15px !important;
}

.padding-top {
  padding-top: 15px !important;
}

.avatar-resize {
  position: relative;
  z-index: 1;
  width: 152px;
  height: 152px;
}

.img-about-height {
  max-height: 500px;
}

.sidebar {
  position: relative;
}

.sidebar-bg {
  margin-bottom: 20px;
}

.sidebar::after {
  content: '';
  position: absolute;
  background: url(../images/sidebar-bg.jpg) top center no-repeat #bababa;
  background-size: cover;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.get-in-touch a {
  position: relative;
  margin-left: 10px;
  z-index: 1;
}

.card-margin {
  margin-bottom: 50px;
}

.tabs-vertical .tabs {
  height: auto;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}

.tabs-vertical .tab {
  width: 100%;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: block;
  float: left;
  line-height: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  text-align: center;
}

.tabs-vertical .tab .active {
  -moz-transition: border-color 0.5s ease;
  -o-transition: border-color 0.5s ease;
  -webkit-transition: border-color 0.5s ease;
  transition: border-color 0.5s ease;
  background-color: #41768f !important;
  color: #fff !important;
  border: 1px solid #333;
}

.tabs-vertical .tab a {
  color: #1e333d;
  display: block;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: color 0.28s ease;
  -moz-transition: color 0.28s ease;
  -o-transition: color 0.28s ease;
  -ms-transition: color 0.28s ease;
  transition: color 0.28s ease;
}

.tabs-vertical .tab a:hover {
  color: #688999;
}

.tabs-vertical .indicator {
  display: none;
}

.input-field:focus {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}

.link-contact:hover {
  color: teal !important;
}

.skills-img > img {
  width: 64px;
  margin-left: 10px;
}

.skills-list > li {
  margin-bottom: 10px;
}

.fancy-gallery img {
  max-width: 256px;
  max-height: 256px;
}

.owl-carousel img {
  margin: 0 auto;
}

.hidden {
  display: none;
}

@media screen and (min-width: 990px) {
  .sidebar {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
  }
}
